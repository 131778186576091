<template lang="">
    <div>
        <Detail />
    </div>
</template>
<script>
export default {
    components:{
        Detail : () => import("@/components/property/Detail")
    }
}
</script>
<style lang="">
    
</style>